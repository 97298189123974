import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  NgModule,
} from '@angular/core';
import {
  getAnalytics,
  provideAnalytics,
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/analytics';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { QRCodeModule } from 'angularx-qrcode';
import { environment } from 'libs/shared/src/lib/environments/environment';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { SocketIoModule } from 'ngx-socket-io';
import { ToastComponent } from '../../../../libs/shared/src/lib/components/toast/toast.component';
import { WsService } from '../../../chat/src/app/services/ws.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AlertComponent } from './components/alert/alert.component';
import { AnnouncementComponent } from './components/announcement/announcement.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { HeaderComponent } from './components/header/header.component';
import { KpiComponent } from './components/kpi/kpi.component';
import { MediaInputComponent } from './components/media-input/media-input.component';
import { StepperItemComponent } from './components/stepper-item/stepper-item.component';
import { AdminComponent } from './pages/admin/admin.component';
import { ActivateChannelComponent } from './pages/admin/chat/channels/activate-channel/activate-channel.component';
import { AddChannelComponent } from './pages/admin/chat/channels/add-channel/add-channel.component';
import { ChannelsComponent } from './pages/admin/chat/channels/channels.component';
import { AddOperatorComponent } from './pages/admin/chat/operators/add-operator/add-operator.component';
import { OperatorsComponent } from './pages/admin/chat/operators/operators.component';
import { AdminNavBarComponent } from './pages/admin/nav-bar/nav-bar.component';
import { PlansComponent } from './pages/admin/plans/plans.component';
import { CampaignComponent } from './pages/admin/send/campaigns-list/campaign/campaign.component';
import { CampaignsListComponent } from './pages/admin/send/campaigns-list/campaigns-list.component';
import { RankingComponent } from './pages/admin/send/stats/ranking/ranking.component';
import { SendStatsComponent } from './pages/admin/send/stats/stats.component';
import { AddTemplateComponent } from './pages/admin/send/templates-list/add-template/add-template.component';
import { TemplatesListComponent } from './pages/admin/send/templates-list/templates-list.component';
import { AddUserTagComponent } from './pages/admin/send/users-list/add-user-tag/add-user-tag.component';
import { AddUserComponent } from './pages/admin/send/users-list/add-user/add-user.component';
import { UsersListComponent } from './pages/admin/send/users-list/users-list.component';
import { ErrorComponent } from './pages/error/error.component';
import { AdminSignInComponent } from './pages/sign-in/admin/admin.component';
import { AuthComponent } from './pages/sign-in/admin/auth/auth.component';
import { RegisterComponent } from './pages/sign-in/admin/register/register.component';
import { IndexComponent } from './pages/sign-in/index/index.component';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { UserSignInComponent } from './pages/sign-in/user/user.component';
import { ScanComponent } from './pages/template/scan/scan.component';
import { TemplateSendComponent } from './pages/template/send/send.component';
import { TemplateComponent } from './pages/template/template.component';
import { HistoryComponent } from './pages/user/history/history.component';
import { AddPollOptionComponent } from './pages/user/send/content/add-poll-option/add-poll-option.component';
import { ChooseTemplateComponent } from './pages/user/send/content/choose-template/choose-template.component';
import { ContentComponent } from './pages/user/send/content/content.component';
import { FilterDrawerComponent } from './pages/user/send/recipients/filter-drawer/filter-drawer.component';
import { RecipientsComponent } from './pages/user/send/recipients/recipients.component';
import { UploadContactsComponent } from './pages/user/send/recipients/upload-contacts/upload-contacts.component';
import { SendComponent } from './pages/user/send/send.component';
import { FeedbackModalComponent } from './pages/user/send/trigger/feedback-modal/feedback-modal.component';
import { TriggerComponent } from './pages/user/send/trigger/trigger.component';
import { UserComponent } from './pages/user/user.component';
import { EnvPipe } from './pipes/env.pipe';
import { LoadingPipe } from './pipes/loading.pipe';

// shared components
import { provideClarity } from '@shared/clarity';
import { SharedModule } from '@shared/shared.module';
import { MarkdownPipe } from 'libs/shared/src/lib/pipes/markdown.pipe';
import { AddCategoryComponent } from './pages/admin/chat/categories/add-category/add-category.component';
import { CategoriesComponent } from './pages/admin/chat/categories/categories.component';
import { SelectFlowComponent } from './pages/admin/chat/channels/select-flow/select-flow.component';
import { AddFlowComponent } from './pages/admin/chat/flows/add-flow/add-flow.component';
import { FlowComponent } from './pages/admin/chat/flows/flow/flow.component';
import { NodeComponent } from './pages/admin/chat/flows/flow/node/node.component';
import { FlowsComponent } from './pages/admin/chat/flows/flows.component';
import { AddWarmupComponent } from './pages/admin/warmup/add-warmup/add-warmup.component';
import { WarmupComponent } from './pages/admin/warmup/warmup.component';

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    SendComponent,
    RecipientsComponent,
    TriggerComponent,
    ContentComponent,
    UserSignInComponent,
    AdminSignInComponent,
    AdminComponent,
    AddUserComponent,
    AddTemplateComponent,
    ChooseTemplateComponent,
    HistoryComponent,
    UserComponent,
    ErrorComponent,
    IndexComponent,
    CampaignsListComponent,
    UsersListComponent,
    TemplatesListComponent,
    HeaderComponent,
    KpiComponent,
    PlansComponent,
    RegisterComponent,
    AuthComponent,
    UploadContactsComponent,
    LoadingPipe,
    MediaInputComponent,
    ToastComponent,
    TemplateComponent,
    ScanComponent,
    TemplateSendComponent,
    FilterDrawerComponent,
    AddPollOptionComponent,
    AnnouncementComponent,
    CampaignComponent,
    StepperItemComponent,
    AlertComponent,
    SendStatsComponent,
    AdminNavBarComponent,
    OperatorsComponent,
    ChannelsComponent,
    RankingComponent,
    AddUserTagComponent,
    FeedbackComponent,
    FeedbackModalComponent,
    AddChannelComponent,
    ActivateChannelComponent,
    AddOperatorComponent,
    FlowComponent,
    FlowsComponent,
    NodeComponent,
    EnvPipe,
    MarkdownPipe,
    AddFlowComponent,
    SelectFlowComponent,
    CategoriesComponent,
    AddCategoryComponent,
    WarmupComponent,
    AddWarmupComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
  imports: [
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    QRCodeModule,
    NgxChartsModule,
    AppRoutingModule,
    AngularFireFunctionsModule,
    FormsModule,
    SocketIoModule,
    NgxMaskDirective,
    NgxMaskPipe,
    ReactiveFormsModule,
  ],
  providers: [
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAnalytics(() => getAnalytics()),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideFunctions(() => getFunctions()),
    ScreenTrackingService,
    UserTrackingService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    WsService,
    provideNgxMask(),
    provideHttpClient(withInterceptorsFromDi()),
    provideClarity({
      enabled: true,
      projectId: environment.clarityId,
    }),
  ],
})
export class AppModule {}
